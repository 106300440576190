var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled from '@emotion/styled';
import { space, textAlign } from 'styled-system';
import { Grid, GridItem } from '../../common/components/Grid';
import ReCaptchaDisclaimer from '../../common/components/ReCaptchaDisclaimer';
import Separator from '../../common/components/Separator';
import { BodyRegularBR2 } from '../../common/components/Typography';
var TextContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, textAlign);
TextContainer.defaultProps = {
    py: ['sp_16', null, 'sp_24', null],
    textAlign: 'center',
};
var separatorStyles = {
    mr: ['-16px', null, 'sp_0'],
    ml: ['-16px', null, 'sp_0'],
};
var FooterLegalText = function (_a) {
    var legalText = _a.legalText;
    return (React.createElement(Grid, null,
        React.createElement(GridItem, null,
            React.createElement(Separator, __assign({}, separatorStyles)),
            React.createElement(TextContainer, null,
                React.createElement(BodyRegularBR2, null, legalText),
                React.createElement(ReCaptchaDisclaimer, { mt: "sp_16" })))));
};
export default FooterLegalText;
var templateObject_1;
